import toast from 'react-hot-toast';
import { SuccessOrFailure } from 'src/types/successOrFailure';
import { TextValue } from 'src/types/textValue';
import { format, parseISO } from 'date-fns';
import { getAuthResult } from './auth-result-storage';
import { azureAdRequest } from 'src/config';
import { msalApplicationInstance } from './msal-application-instance';

export const showToast = (result: SuccessOrFailure) => {
  if (result.success) {
    toast.success(result.message);
  } else {
    toast.error(result.message);
  }
};

export const downloadFile = (fileName: string, promise: Promise<any>) => {
  const safeFileName = fileName.replace('/', '_');
  return promise
    .then((file) => {
      const url = window.URL.createObjectURL(new Blob([file]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', safeFileName);
      document.body.appendChild(link);
      link.click();
    });
};

export const a11yProps = (index: number) => {
  const tabA11y = {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
  return tabA11y;
};

export const convertCsvToArray = (value: string) => {
  return value ? value.split(',').map((x) => x.trim()) : [];
};

export const convertToNumbers = (values: string[]) => values.map((x) => parseInt(x, 10));

export const validateCsvValuesAsNumbersArray = (
  csvValues: string,
  allowEmpty = true
) => {
  if (!csvValues) {
    return allowEmpty;
  }
  const values = convertCsvToArray(csvValues);
  return values.every((x) => /^\d+$/.test(x.trim()));
};

export const danishLanguageCode = 'da';
export const languages = [
  { text: 'Danish', value: danishLanguageCode, default: true },
  { text: 'English', value: 'en', default: false },
  { text: 'Danish / English', value: 'da_en', default: false },
  { text: 'Norwegian', value: 'nb', default: false },
  { text: 'Swedish', value: 'sb', default: false },
];

export const isProductDeliverySetup = (productDeliveryType: string) =>
  (productDeliveryType && productDeliveryType.toLowerCase() !== 'none') || false;

export const trimLeadingSlashes = (url: string) => url?.trim()?.replace(/^\/+/, '');
export const trimTrailingSlashes = (url: string) => url?.trim()?.replace(/\/+$/, '');

export const yesNoOptions: TextValue[] = [
  {
    text: 'Yes',
    value: 'true',
  },
  {
    text: 'No',
    value: 'false',
  },
];

export const toRelativeDate = (date: Date) => {
  const minute = 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  const month = day * 30;
  const year = month * 12;

  const now = new Date();
  const secondsAgo = Math.floor((now.getTime() - date.getTime()) / 1000);

  if (secondsAgo < minute) {
    return 'just now';
  }
  if (secondsAgo < 2 * minute) {
    return 'a minute ago';
  }
  if (secondsAgo < hour) {
    return `${Math.floor(secondsAgo / minute)} minutes ago`;
  }
  if (Math.floor(secondsAgo / hour) === 1) {
    return '1 hour ago';
  }
  if (secondsAgo < day) {
    return `${Math.floor(secondsAgo / hour)} hours ago`;
  }
  if (secondsAgo < day * 2) {
    return 'yesterday';
  }
  if (secondsAgo < week) {
    return `${Math.floor(secondsAgo / day)} days ago`;
  }
  if (secondsAgo < month) {
    return `${Math.floor(secondsAgo / week)} weeks ago`;
  }
  if (secondsAgo < year) {
    return `${Math.floor(secondsAgo / month)} months ago`;
  }
  return `${Math.floor(secondsAgo / year)} years ago`;
};

export const formatDate = (date: string) => format(parseISO(date), 'dd-MM-yyyy');
export const formatDateTime = (date: string) => format(parseISO(date), 'dd-MM-yyyy HH:mm');

export const getToken = async () => {
  const storageAuthResult = getAuthResult();
  const accessTokenRequest = {
    scopes: azureAdRequest.scopes,
    account: storageAuthResult.account,
  };
  const authResult = await msalApplicationInstance.acquireTokenSilent(accessTokenRequest);
  return authResult;
};
