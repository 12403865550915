import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import {
  Button,
  CssBaseline,
  IconButton,
  Snackbar,
  ThemeProvider,
} from '@material-ui/core';
import './i18n';
import RTL from './components/RTL';
import { apiConfig, gtmConfig, cspUrlsConfig } from './config';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import gtm from './lib/gtm';
import routes from './routes';
import { createCustomTheme } from './theme';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { getToken, trimTrailingSlashes } from './common/utils';
import CloseIcon from '@material-ui/icons/Close';
import { Helmet } from 'react-helmet-async';

const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const navigate = useNavigate();
  const [showSignOrderIssuesAlert, setShowSignOrderIssuesAlert] =
    useState(false);
  const [signOrderIssuesAlertsMessage, setSignOrderIssuesAlertsMessage] =
    useState(null);

  useScrollReset();

  const subscribeToSignOrderIssuesAlerts = () => {
    const { baseUrl } = apiConfig;
    const url = `${trimTrailingSlashes(
      baseUrl
    )}/signalrhubs/signorder-issues-alerts-hub`;
    const connection = new HubConnectionBuilder()
      .withUrl(url, {
        accessTokenFactory: async () => {
          const authResult = await getToken();
          return authResult.accessToken;
        },
      })
      .withAutomaticReconnect()
      .build();

    const start = () => {
      console.log('Connecting to sign order issues alerts...');
      connection
        .start()
        .then(() => {
          connection.on('Connected', () => {
            console.log('Connecting to sign order issues alerts...succeeded');
          });
          connection.on('Alert', (message) => {
            console.log(message);
            setSignOrderIssuesAlertsMessage(message);
            setShowSignOrderIssuesAlert(true);
          });
        })
        .catch(() => {});
    };

    connection.onclose(() => {
      setTimeout(() => start, 5000);
    });

    start();
  };

  useEffect(() => {
    gtm.initialize(gtmConfig);
    subscribeToSignOrderIssuesAlerts();
  }, []);

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  const cspUrl = cspUrlsConfig.cspUrls;

  return (
    <ThemeProvider theme={theme}>
      <>
        <Helmet>
          <meta
            httpEquiv="Content-Security-Policy"
            content={cspUrl}
          />
        </Helmet>
        <RTL direction={settings.direction}>
          <CssBaseline />
          <Toaster position="top-right" />
          {/* <SettingsDrawer />
        {auth.isInitialized ? content : <SplashScreen />} */}
          {content}
          <Snackbar
            open={showSignOrderIssuesAlert}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            autoHideDuration={15000}
            onClose={() => setShowSignOrderIssuesAlert(false)}
            message={signOrderIssuesAlertsMessage}
            action={
              <>
                <Button
                  color="primary"
                  size="small"
                  onClick={() => {
                    navigate('/dashboard/support/signorders/issues-overview');
                    setShowSignOrderIssuesAlert(false);
                  }}
                >
                  Details
                </Button>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => setShowSignOrderIssuesAlert(false)}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </>
            }
          />
        </RTL>
      </>
    </ThemeProvider>
  );
};

export default App;
