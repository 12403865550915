import { useEffect } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, Typography } from '@material-ui/core';
import type { Theme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useAuth from '../../hooks/useAuth';
import SearchIcon from '../../icons/Search';
import XIcon from '../../icons/X';
import MenuIcon from '../../icons/Menu';
import UserAddIcon from '../../icons/UserAdd';
import LockOpen from '@material-ui/icons/LockOpen';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const sections = [
  {
    title: 'Support',
    path: '/dashboard/support',
    items: [
      {
        title: 'Sign Orders',
        path: '/dashboard/support/signorders',
        children: [
          {
            title: 'Search',
            path: '/dashboard/support/signorders/search',
            icon: <SearchIcon fontSize="small" />,
          },
          {
            title: 'Issues overview',
            path: '/dashboard/support/signorders/issues-overview',
            icon: <XIcon fontSize="small" />,
          },
          {
            title: 'Decode reference',
            path: '/dashboard/support/signorders/decode-reference',
            icon: <LockOpen fontSize="small" />,
          },
        ],
      },
    ],
  },
  {
    title: 'Customers',
    path: '/dashboard/customers',
    items: [
      {
        title: 'Search',
        path: '/dashboard/customers/search',
        icon: <SearchIcon fontSize="small" />,
      },
      {
        title: 'Create',
        path: '/dashboard/customers/create',
        icon: <UserAddIcon fontSize="small" />,
      },
    ],
  },
  {
    title: 'Users',
    path: '/dashboard/users',
    items: [
      {
        title: 'Search',
        path: '/dashboard/users/search',
        icon: <SearchIcon fontSize="small" />,
      },
      {
        title: 'Create',
        path: '/dashboard/users/create',
        icon: <UserAddIcon fontSize="small" />,
      },
    ],
  },
  {
    title: 'Approvers',
    path: '/dashboard/approvers',
    items: [
      {
        title: 'Search',
        path: '/dashboard/approvers/search',
        icon: <SearchIcon fontSize="small" />,
      },
      {
        title: 'Create',
        path: '/dashboard/approvers/create',
        icon: <UserAddIcon fontSize="small" />,
      },
    ],
  },
  {
    title: 'Scheduled tasks',
    path: '/dashboard/scheduled-tasks',
    items: [
      {
        title: 'List',
        path: '/dashboard/scheduled-tasks/list',
        icon: <MenuIcon fontSize="small" />,
      },
    ],
  },
  {
    title: 'Branding',
    path: '/dashboard/branding',
    items: [
      {
        title: 'Branding Admin',
        path: '/dashboard/branding/brandingadmin',
      },
    ],
  },
  {
    title: 'Forms',
    items: [
      {
        title: 'Old sign orders clean up',
        path: '/dashboard/forms/old-signorders-cleanup',
        icon: <MenuIcon fontSize="small" />,
      },
    ],
  },
  {
    title: 'Test',
    path: '/dashboard/test',
    items: [
      {
        title: 'Sign',
        path: '/dashboard/test/sign',
      },
    ],
  },
];

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const currentEnvironment = process.env.REACT_APP_ENV || 'unknown';

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex',
            },
            justifyContent: 'center',
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2,
            }}
          >
            <Box sx={{ ml: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {user.name}
              </Typography>
              <Typography
                color="gray"
                variant="subtitle1"
              >
                <div>{currentEnvironment}</div>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 0.2,
                },
              }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
